import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


const REFRESH_TIME = 10000;

@Injectable({
  providedIn: 'root',
})
export class CaronteService {
 
  endpoints: any;
  charonSession: any;
  token: any;
  isCharonSessionValid = false;

  constructor(private http: HttpClient) {}

  init() {

    return this.http.get<any>(environment.charonUrl, { observe: 'response' })
    .pipe(
      tap((res: HttpResponse<any>) => {
        this.endpoints = res.body?.links;
        this.charonSession = res.headers.get('x-charon-session');
        this.isCharonSessionValid = true;
 
        setTimeout(() => this.isCharonSessionValid = false, REFRESH_TIME);
      }),
    );
    
  }

  get(ref: string, params?: any): any {
     
    if (this.isCharonSessionValid) {
      return this.http.get<any>(this.getEndpoint(ref), this.getHeaders(params));
    }
 
    return this.init()
    .pipe(
      switchMap(() => this.http.get<any>(this.getEndpoint(ref), this.getHeaders(params))),
    );
  }

  post(ref: string, body: any, headers?: any): any {

    if (this.isCharonSessionValid) {
      return this.http.post<any>(
        this.getEndpoint(ref),
        body,
        this.getHeaders(headers)
      );
    }

    return this.init().pipe(
      switchMap(() =>
        this.http.post<any>(
          this.getEndpoint(ref),
          body,
          this.getHeaders(headers)
        )
      )
    );
  }

  getToken() {
    this.token = sessionStorage.getItem('Authorization1');
  }

  private getEndpoint(ref: string) {
    return this.endpoints.find((links: { rel: string }) => links.rel === ref)
      .href;
  }

  private getHeaders(params: any) {

    params = Object.assign({}, params, {
      segment:
        params && params.segment ? String(params.segment).toLowerCase() : '',
    });

    this.getToken();

    let headers = new HttpHeaders({
      'x-charon-session': this.charonSession,
      'x-itau-correlationID': environment.contentstack.delivery_token,
      'x-origin-cf-apigw': environment.contentstack.api_key,
      'x-Charon-Params': btoa(JSON.stringify(params)),
      'Content-Type': 'application/json'
    });
    
    if (params) {
      const paramBase64 = btoa(JSON.stringify(params));
      headers = headers.set('X-Charon-Params', paramBase64);
    }
    
    return { headers };
  }
  
}
