<app-header></app-header>

<section class="voxel-container search-form" *ngIf="isLogged">
  <div class="title">
    <h1 class="voxel-text-title-01 voxel-mb-8">Visualizador dos dados do FaaS</h1>
  </div>

  <form class="form-control voxel-pb-4 voxel-mb-7" [formGroup]="form">
    <h2 class="voxel-mb-6 voxel-p-4 voxel-text-label-xlarge-bold">Seleção das campanhas</h2>

    <div class="voxel-row voxel-p-4">
      <div class="voxel-col-6">
        <div class="voxel-row voxel-pb-6">
          <div class="voxel-col-12">
            <voxel-form-field label="Identificação da campanha" msgErr="Identificação da campanha é obrigatória">
              <voxel-select formControlName="idCampaign">
                <voxel-option [value]="''">Selecione uma campanha</voxel-option>
                <voxel-option *ngFor="let campaign of campaigns | keyvalue" [value]="campaign.key">{{ campaign.value.title }}</voxel-option>
              </voxel-select>
            </voxel-form-field>
          </div>
        </div>

        <div class="voxel-row">
          <div class="voxel-col-6">
            <voxel-form-field label="data inicial" msgErr="data inválida">
              <input 
                placeholder="DD/MM/AAAA"
                type="tel"
                voxelInput
                voxelDatepickerInput
                formControlName="initialDate" 
                #initialDate="voxelDatepickerInput"
              />
              <voxel-datepicker
                [input]="initialDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (toggle)="showCorrectCalendar($event)"
              >
              </voxel-datepicker>
            </voxel-form-field>
          </div>
          <div class="voxel-col-6">
            <voxel-form-field label="data final" msgErr="data inválida">
              <input placeholder="DD/MM/AAAA" type="tel" #finalDate="voxelDatepickerInput" voxelInput voxelDatepickerInput formControlName="finalDate" />
              <voxel-datepicker
                [input]="finalDate"
                [minDate]="form.value.initialDate"
                [maxDate]="maxDate"
                (toggle)="showCorrectCalendar($event)"
              >
              </voxel-datepicker>
            </voxel-form-field>
          </div>
        </div>
      </div>
      <div class="voxel-col-6">
        <div class="voxel-row">
          <div class="voxel-col-12 container-buttons">
            <button class="voxelButton voxel-mb-4" voxelButton (click)="send()" type="submit" [disabled]="!form.valid">selecionar</button>
            <button class="voxelButtonContextual" voxelButtonContextual [disabled]="form.untouched" type="button" (click)="clean()">limpar</button>
            <div role="alert" class="hidden-element">
              <span aria-live="assertive" aria-atomic="true" *ngIf="showFeedbackClearForm">Formulário limpo</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div 
    class="container-loading"
    role="status"
    aria-live="polite"
    aria-atomic="true"
    *ngIf="loadingInformation || campaignDetails"
  >
    <span *ngIf="loadingInformation" class="hidden-element">buscando informações</span>
    <span *ngIf="campaignDetails && showFeedbackResultMessage" class="hidden-element">Foram encontrados {{ campaignDetails.responses.length }} registros.</span>
    <voxel-loading-circle size="large" *ngIf="loadingInformation"></voxel-loading-circle>
  </div>

  <div *ngIf="!loadingInformation && campaignDetails">
    <app-resume [infos]="campaignDetails"></app-resume>
    <app-datatable [data]="campaignDetails.responses"></app-datatable>
  </div>

</section>
