import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { CaronteService } from './caronte.service';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  
  private idCampaign!: string;
  private subjectFile = new BehaviorSubject({});

  constructor(public caronteService: CaronteService) { }

  
  findOne(campaign: CampaingParams): Observable<any> {
    
    return this.caronteService.get(`potenciais`, {
      "data_inicio":this.formateDate(campaign.initialDate),"data_fim":this.formateDate(campaign.finalDate),"id_campanha":campaign.idCampaign
    });
    
  }

  private formateDate(str: string): string {
    const regx = new RegExp(/[0-9]{2}[0-9]{2}[0-9]{4}/, 'g')
    let date = str;
    if (regx.test(str)) {
      date = `${str.substr(0, 2)}/${str.substr(2, 2)}/${str.substr(4, 4)}`;
    }
    
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  }

  public storeFileData(data: any, id: any) {
    this.subjectFile.next({ data });
    this.idCampaign = id;
  }

  public get currentFileData(): Observable<any> {
    return this.subjectFile.asObservable();
  }

  public get getIdCampaign(): string {
    return this.idCampaign;
  }

  public convertToCSV(data: any) {
    data = data.map(({ txt_camo_idef_camp, ttl, ...rest }: any) => rest);
    const replacer = (_: unknown, value: null | string) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const aux = data.map((row: any) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(';')
    );
    aux.unshift(header.join(';'));
    const csv: string = aux.join('\r\n');
    return csv;
  }
}

export interface CampaingParams {
  initialDate: string;
  finalDate: string;
  idCampaign: string;
}
