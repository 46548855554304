<div class="table voxel-mt-6 voxel-mb-6" *ngIf="columns.length">
  <div class="table__header">
    <a aria-label="Ir para a página de download dos dados" [routerLink]="['/download']" class="download" *ngIf="data && data.length">
      <voxel-icon>download_outline</voxel-icon>
    </a>
  </div>
  <div class="table__container">
    <table>
      <thead>
        <tr>
          <th *ngFor="let column of columns">{{ column }}</th>
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let info of data">
          <td *ngFor="let column of columns">{{ info[column] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>