export const taacMock = [
  {
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#011.441.953-14",
      "cpf_cnpj": "011.441.953-14",
      "telefone": "(11) 98692-8755",
      "ttl": 3773617200,
      "nome_completo": "aline costa",
      "dat_hor_atui": "2023-07-11T15:37:13.618Z"
  },
  {
      "numero": "142",
      "opt_in": false,
      "complemento": null,
      "txt_camo_idef_camp": "cpf_cnpj#012.345.678-90",
      "cpf_cnpj": "012.345.678-90",
      "telefone": "(11) 94976-7581",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04152-020",
      "estado": "SP",
      "bairro": "ola",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "Praça Evangelista",
      "nome_completo": "Amanda Futemma",
      "dat_hor_atui": "2023-08-29T22:47:24.306Z"
  },
  {
      "numero": "142",
      "complemento": "alo",
      "txt_camo_idef_camp": "cpf_cnpj#063.147.301-71",
      "cpf_cnpj": "063.147.301-71",
      "telefone": "(11) 94976-7581",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04152-020",
      "estado": "SP",
      "bairro": "galera de cowboy",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "Praça Evangelista",
      "nome_completo": "Amanda Futemma",
      "dat_hor_atui": "2023-08-29T22:51:51.128Z"
  },
  {
      "numero": "1000",
      "complemento": null,
      "txt_camo_idef_camp": "cpf_cnpj#066.428.880-49",
      "cpf_cnpj": "066.428.880-49",
      "telefone": "(11) 99294-2223",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04863-120",
      "estado": "SP",
      "bairro": "vila neste",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "rua teste",
      "nome_completo": "Eduardo Rosario",
      "dat_hor_atui": "2023-09-22T14:34:42.853Z"
  },
  {
      "numero": "1500",
      "complemento": "casa",
      "txt_camo_idef_camp": "cpf_cnpj#203.141.620-03",
      "cpf_cnpj": "203.141.620-03",
      "telefone": "(11) 99294-2223",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04863-120",
      "estado": "SP",
      "bairro": "Vila Teste",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "Rua teste",
      "nome_completo": "Eduardo Rosario",
      "dat_hor_atui": "2023-08-29T22:52:01.308Z"
  },
  {
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#216.441.508-67",
      "cpf_cnpj": "216.441.508-67",
      "telefone": "(19) 99111-1214",
      "ttl": 3773617200,
      "nome_completo": "carla",
      "dat_hor_atui": "2023-07-11T15:18:40.192Z"
  },
  {
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#217.049.998-98",
      "cpf_cnpj": "217.049.998-98",
      "telefone": "(19) 99217-3677",
      "ttl": 3773617200,
      "nome_completo": "Jose",
      "dat_hor_atui": "2023-07-11T15:30:35.603Z"
  },
  {
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#297.887.658-15",
      "cpf_cnpj": "297.887.658-15",
      "telefone": "(19) 99912-1214",
      "ttl": 3773617200,
      "nome_completo": "carla",
      "dat_hor_atui": "2023-07-11T15:15:56.511Z"
  },
  {
      "opt_in": false,
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#331.737.080-75",
      "cpf_cnpj": "331.737.080-75",
      "telefone": "(11) 99294-2223",
      "ttl": 3773617200,
      "nome_completo": "djei jdiejdie",
      "dat_hor_atui": "2023-06-26T23:10:23.796Z"
  },
  {
      "numero": "152",
      "opt_in": false,
      "complemento": null,
      "txt_camo_idef_camp": "cpf_cnpj#372.051.940-67",
      "cpf_cnpj": "372.051.940-67",
      "telefone": "(11) 99294-2223",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04863-120",
      "estado": "SP",
      "bairro": "oskako",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "11992942223",
      "nome_completo": "aoskaos skaosk",
      "dat_hor_atui": "2023-08-29T22:45:24.005Z"
  },
  {
      "numero": "138",
      "opt_in": false,
      "complemento": "casa 2",
      "txt_camo_idef_camp": "cpf_cnpj#414.593.658-28",
      "cpf_cnpj": "414.593.658-28",
      "telefone": "(11) 96831-1511",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "02320-120",
      "estado": "SP",
      "bairro": "Pq Casa de Pedra",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "Rua Baquio Preto",
      "nome_completo": "Alana Nascimento da SIlva",
      "dat_hor_atui": "2023-08-29T22:50:28.428Z"
  },
  {
      "numero": "142",
      "opt_in": false,
      "complemento": null,
      "txt_camo_idef_camp": "cpf_cnpj#456.070.158-07",
      "cpf_cnpj": "456.070.158-07",
      "telefone": "(11) 94976-7581",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04152-020",
      "estado": "SP",
      "bairro": "vl sto estefano",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "Praça Evangelista",
      "nome_completo": "Amanda Futemma",
      "dat_hor_atui": "2023-08-29T22:44:39.385Z"
  },
  {
      "opt_in": false,
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#501.335.450-16",
      "cpf_cnpj": "501.335.450-16",
      "telefone": "(19) 99087-9789",
      "ttl": 3773617200,
      "nome_completo": "Guarni Lucas Teste",
      "dat_hor_atui": "2023-06-07T15:15:03.562Z"
  },
  {
      "opt_in": false,
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#725.723.730-17",
      "cpf_cnpj": "725.723.730-17",
      "telefone": "(92) 93171-4363",
      "ttl": 3773617200,
      "nome_completo": "Maria Aparecida",
      "dat_hor_atui": "2023-06-19T15:30:49.671Z"
  },
  {
      "numero": "142",
      "opt_in": false,
      "complemento": null,
      "txt_camo_idef_camp": "cpf_cnpj#767.983.140-82",
      "cpf_cnpj": "767.983.140-82",
      "telefone": "(11) 94976-7581",
      "cidade": "São Paulo",
      "ttl": 3776122800,
      "cep": "04152-020",
      "estado": "SP",
      "bairro": "vl sto estefano",
      "opcoes_2": "opção 2,opção 3",
      "cod_camp": "bltce626c3e7cb587a3",
      "opcoes": "opção 2",
      "data": "21/05/1995",
      "endereco": "Praça Evangelista",
      "nome_completo": "teste amanda",
      "dat_hor_atui": "2023-10-09T11:05:33.000Z"
  },
  {
      "opt_in": false,
      "cod_camp": "bltce626c3e7cb587a3",
      "txt_camo_idef_camp": "cpf_cnpj#788.584.663-65",
      "cpf_cnpj": "788.584.663-65",
      "telefone": "(18) 98827-8899",
      "ttl": 3773617200,
      "nome_completo": "Aline de souaz",
      "dat_hor_atui": "2023-07-11T15:46:12.609Z"
  },
  {
      "numero": "1000",
      "complemento": null,
      "txt_camo_idef_camp": "cpf_cnpj#996.443.580-07",
      "cpf_cnpj": "996.443.580-07",
      "telefone": "(11) 99294-2223",
      "cidade": "São Paulo",
      "ttl": 3773617200,
      "cep": "04863-120",
      "estado": "SP",
      "bairro": "vila teste",
      "cod_camp": "bltce626c3e7cb587a3",
      "endereco": "rua teste",
      "nome_completo": "aoskaos skaosk",
      "dat_hor_atui": "2023-09-05T17:47:30.494Z"
  }
]