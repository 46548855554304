import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const idToken = localStorage.getItem('id_token');

    if (idToken) {
      request = request.clone({
        setHeaders: { authorization: `Bearer ${idToken}` },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        let handled = false;

        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            if(error.status !== 404) {
              this.router.navigateByUrl(`/error/${error.status}`);
              handled = true;
            }
          }
        }

        if (handled) {
          return of(error);
        }

        return throwError(error);
      })
    );
  }
}
