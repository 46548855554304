export const environment = {
  production: false,
  
  clientId: '2821300c-6394-4b0c-910f-1b07aecc32fc',
  authority: 'https://login.microsoftonline.com/591669a0-183f-49a5-98f4-9aa0d0b63d81',
  contentstack: {
    api_key: 'bltaef1200413a7ff7c',
    delivery_token: 'cs36d69b070fc54c6eb586b7f0',
    environment: 'homolog'
  },
  taac: {
    token: '511b69e6-6528-4e4b-8c47-dbb46b1e1b54',
    email: 'martechprodutostaac@taacteste.com.br'
  },
  charonUrl: 'https://apicd.hom.cloud.itau.com.br/charon/bg6l5eoz'
};
