import { Component, OnInit, Input } from '@angular/core';
import { Entry } from 'src/app/core/services/contentstack/contentstack.interface';

export interface InfosProps {
  campaign: Entry
  responses: any[];
}

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  constructor() { }

  @Input() infos!: InfosProps;
  totalResponses!: number;
  timeActive!: number;


  ngOnInit(): void {
    if (this.infos) {
      this.totalResponses = this.infos.responses.length;
      this.timeActive = this.calculateActiveTime();
    }
  }

  private calculateActiveTime(): number {
    const today = new Date().getTime();
    const startDate = new Date(this.infos.campaign.init_date_campaign).getTime();

    return Math.round((today - startDate) / (1000 * 3600 * 24));
  }
}
