import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService } from '@azure/msal-angular';
import { RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { filter, map, finalize, catchError } from 'rxjs/operators';

import { CampaignService, CampaingParams } from 'src/app/core/services/campaign.service';
import { ContentstackService } from 'src/app/core/services/contentstack/contentstack.service';
import { Entry } from 'src/app/core/services/contentstack/contentstack.interface';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { taacMock } from './taacMock';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(DOCUMENT) private document: Document,
    private readonly route: ActivatedRoute,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly authService: MsalService,
    private readonly campaignService: CampaignService,
    private readonly contentstack: ContentstackService
  ) { }

  form = new FormGroup({
    idCampaign: new FormControl('', [ Validators.required]),
    initialDate: new FormControl('', [Validators.required]),
    finalDate: new FormControl('', Validators.required)
  });
  isLogged = false;
  minDate: string;
  maxDate: string;
  taacToken: string = '';

  campaigns: { [key: string]: Entry } = {};
  campaignDetails: {campaign: Entry, responses: any[]} | null = null;
  loadingInformation = false;
  showFeedbackClearForm = false;
  showFeedbackResultMessage = false;

  async ngOnInit(): Promise<void> {
    this.minDate = '01/08/2021';
    this.maxDate = new Date().toLocaleString();

    this.route.queryParams.subscribe((params) => {
      if (!environment.production && params.taac === environment.taac.token) {
        this.taacToken = params.taac;
        localStorage.setItem('id_token', this.taacToken);
        this.setIsLogged();
        this.loadCampaigns();
        return;
      }
      this.msalAuthentication();
    });
  }

  send(): void {
    if (!this.form.valid) { return; }

    this.campaignDetails = null;
    this.loadingInformation = true;

    const search$ = this.taacToken ? of(taacMock) : this.campaignService.findOne(this.form.value as CampaingParams)

    search$.pipe(
      map((response) => {
        for (const data of response) {
          for (const [key, value] of Object.entries(data)) {
            if (value && typeof value === 'object' && (value as any).formatted !== undefined) {
              data[key] = (value as any).formatted;
            }
          }
        }
        this.campaignService.storeFileData(
          response,
          this.form.value.idCampaign
        );
        this.campaignDetails = {
          campaign: this.campaigns[this.form.value.idCampaign],
          responses: response
        }
        console.log('response', response)
      }),
      catchError((error) => {
        console.log('error', error)
        if (error.status === 404) {
          this.campaignDetails = {
            campaign: this.campaigns[this.form.value.idCampaign],
            responses: []
          };
        }
        return of({});
      }),
      finalize(() => {
        this.loadingInformation = false;
        this.showFeedbackResultMessage = true;
        setTimeout(() => {
          this.showFeedbackResultMessage = false;
        }, 500);
      })
    ).subscribe();
  }

  clean(): void {
    this.form.reset();
    this.form.markAsUntouched();
    this.form.markAsPristine();
    this.campaignDetails = null;

    this.showFeedbackClearForm = true;
    setTimeout(() => {
      this.showFeedbackClearForm = false;
    }, 1000);

  }
  /* istanbul ignore next */
  showCorrectCalendar(event: any): void {
    const windowSize = this.getWindowInnerSize();
    if ( windowSize.width <= 1280) {
      event.source.isMobile = true;
      setTimeout(() => {
        const dialog = this.document.getElementsByClassName('voxel-datepicker')[0];
        if (dialog && windowSize.height <= 400) {
          dialog.classList.add('full-height-calendar');
        }
      }, 500);
    }
    
    return event.source.isMobile;
  }
  
  private getWindowInnerSize() {
    const window = this.document.defaultView;

    return {
      width: Number(window?.innerWidth),
      height: Number(window?.innerHeight)
    }
  }

  private async loadCampaigns(): Promise<void> {
    try {
      if (this.isLogged) {
        const singleStep = await this.contentstack.getEntryByPermission(this.getUsername());
        singleStep.map((entries) => {
          entries.map(item => ( this.campaigns[item.uid] = item ));
        });

        const multiStep =  await this.contentstack.getMultiStepEntryByPermission(this.getUsername());
        multiStep.map((entries) => {
          entries.map(item => ( this.campaigns[item.uid] = item ));
        });
      }
    } catch {
      this.campaigns = {};
    }
  }

  private msalAuthentication(): void {
    this.setIsLogged();

    this.msalBroadcastService.msalSubject$.pipe(

      filter((msg: EventMessage) => {
        if(msg.eventType === EventType.LOGIN_SUCCESS) {
        localStorage.setItem('id_token', msg.payload ? msg.payload['idToken'] : '');
        }
        this.setIsLogged();
        return msg.eventType === EventType.LOGIN_SUCCESS;
        
      })
    ).subscribe(this.loadCampaigns.bind(this));

    if (!this.isLogged) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    } else {
      this.loadCampaigns();
    }
  }

  private setIsLogged(): void {
    if (this.taacToken) {
      this.isLogged = true;
    } else {
      this.isLogged = this.authService.instance.getAllAccounts().length > 0;
    }
  }

  private getUsername(): string {
    if (this.isLogged) {
      if (this.taacToken) {
        return environment.taac.email;
      }
      return this.authService.instance.getAllAccounts()[0].username;
    }
    return '';
  }
}
