import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoxelIconModule} from '@voxel/components';
import { ResumeComponent } from './resume.component';


@NgModule({
  declarations: [
    ResumeComponent,
  ],
  imports: [
    CommonModule,
    VoxelIconModule,
  ],
  exports: [
    ResumeComponent
  ]
})
export class ResumeModule { }
