import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
    path: '',
    redirectTo: '/formulario',
    pathMatch: 'full'
  },
  {
    path: 'formulario',
    loadChildren: () => import('./pages/search-form/search-form.module').then(m => m.SearchFormModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./pages/download/download.module').then(m => m.DownloadModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/http-error/http-error.module').then(m => m.HttpErrorComponentModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [Location],
  exports: [RouterModule]
})
export class AppRoutingModule {}
