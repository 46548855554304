import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})

export class HeaderComponent implements OnInit {
  constructor(
    private readonly authService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly route: ActivatedRoute
  ) {}
  isLogged = true;
  taacToken: string = '';

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!environment.production && params.taac === environment.taac.token) {
        this.taacToken = params.taac;
      } 
      this.setIsLogged();
      
      if (this.taacToken) {
        return;
      }

      this.msalBroadcastService.msalSubject$.pipe(
        filter((msg: EventMessage) => {
          return msg.eventType === EventType.LOGIN_SUCCESS;
        })
      ).subscribe(this.setIsLogged.bind(this));
    });
  }

  logout() {
    this.authService.logoutRedirect({});
  }
  private setIsLogged(): void {
    if (this.taacToken) {
      this.isLogged = true;
    } else {
      this.isLogged = this.authService.instance.getAllAccounts().length > 0;
    }
  }
}