import { Injectable } from '@angular/core';
import { Stack } from 'contentstack';

import { environment } from 'src/environments/environment';
import { Entry } from './contentstack.interface';

@Injectable({
  providedIn: 'root'
})
export class ContentstackService {
  private stack = Stack(environment.contentstack);

  getEntryByPermission(email: string): Promise<Entry[][]> {
    const query = this.stack.ContentType('template_campanha').Query();
    return query
      .containedIn('permissoes.email.owner', this.getEmailCases(email))
      .toJSON()
      .find();
  }

  getMultiStepEntryByPermission(email: string): Promise<Entry[][]> {
    const query = this.stack.ContentType('template_campanha_multi_step').Query();
    return query
    .containedIn('permissoes.email.owner', this.getEmailCases(email))
    .toJSON()
    .find();
  }

  private getEmailCases(email: string): string[] {
    return [email, email.toLowerCase(), email.toUpperCase()];
  }
}
