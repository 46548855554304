import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFormComponent } from './search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VoxelDatepickerModule, VoxelSelectModule, VoxelButtonModule, VoxelFormFieldModule, VoxelIconModule, VoxelAccordionModule, VoxelInputModule, VoxelLoadingCircleModule } from '@voxel/components';
import { HeaderModule } from 'src/app/core/components/header/header.module';
import { Routes, RouterModule } from '@angular/router';
import { ResumeModule } from './components/resume/resume.module';
import { MyDataTableModule } from './components/datatable/datatabe.module';

const routes: Routes = [
  {
    path: '',
    component: SearchFormComponent
  }
];

@NgModule({
  declarations: [
    SearchFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),

    VoxelFormFieldModule,
    VoxelButtonModule,
    VoxelSelectModule,
    VoxelDatepickerModule,
    VoxelIconModule,
    VoxelInputModule,
    VoxelAccordionModule,
    VoxelLoadingCircleModule,
    HeaderModule,
    ResumeModule,
    MyDataTableModule
  ],
  exports: [
    SearchFormComponent,
  ]
})
export class SearchFormModule { }
