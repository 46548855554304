import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatatableComponent implements OnInit {
  @Input() data: any;
  columns: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.columns = this.getColumns();
  };

  private getColumns(): string[] {
    if (!this.data) { return []; }

    this.data = this.data.map(({ txt_camo_idef_camp, ttl, ...rest }: any) => rest );
    let finalColumns: any = {};
    this.data.forEach((data: any) => {
      if (Object.keys(data).length > Object.keys(finalColumns).length) {
        finalColumns = data;
      }
    });

    return Object.keys(finalColumns).map(item => {
      this.data.map((value: any) => {
        value[item] = typeof value[item] === 'undefined' ? '' : String(value[item]);
        return value;
      });

      return item;
    });
  }
}
