<div class="voxel-mt-7 container-loading" role="status" aria-live="polite" aria-atomic="true" [hidden]="true">
  <span [hidden]="true" *ngIf="totalResponses">Foram encontradas {{ totalResponses }}, no período filtrado.</span>
</div>
<h3 class="voxel-text-label-xlarge-bold voxel-mb-5" *ngIf="infos">Resumo da campanha</h3>
<div class="voxel-row">
  <div class="voxel-col-4">
    <div class="voxel-card">
      <div class="voxel-mb-2">
        <voxel-icon class="voxel-color-text-highlight">
          confirmacao
        </voxel-icon>
      </div>
      <p class="informations">
        <span class="voxel-text-label-xlarge-bold">{{ totalResponses }}</span>
        <span class="voxel-text-body-01">
          respostas individuais neste período
        </span>
      </p>
    </div>
  </div>

  <div class="voxel-col-4">
    <div class="voxel-card">
      <div class="voxel-mb-2">
        <voxel-icon class="voxel-color-text-highlight">
          relogio_outline
        </voxel-icon>
      </div>
      <p class="informations">
        <span class="voxel-text-label-xlarge-bold">{{ timeActive }}</span>
        <span class="voxel-text-body-01">
          dias ativos de formulário
        </span>
      </p>
    </div>
  </div>
</div>
